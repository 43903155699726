<template>
  <div class="container">
    <div class="pa-20">
      <img class="logo" src="~@/assets/img/logo.png" />
      <div class="content ma-t-10">
        <div class="flex f-center back-box" @click="$router.replace('/')">
          <img class="back" src="~@/assets/img/back.png" />
          <span>返回</span>
        </div>

        <div class="flex f-center f-wrap f-jcenter ma-t-80">
          <div class="box green ma-l-20 ma-t-20" @click="$router.push('/greenChannel')">
            <img src="~@/assets/img/greenchannel.png" />
            <div class="ma-t-10" >
              绿色通道
            </div>
          </div>
          <div class="box ma-l-20 ma-t-20" @click="show = true">
            <img src="~@/assets/img/setting.png" />
            <div class="ma-t-10">绑定通道</div>
          </div>
          <div class="box box1 ma-l-20 ma-t-20" @click="exit">
            <img src="~@/assets/img/exit.png" />
            <div class="ma-t-10">退出登录</div>
          </div>
        </div>
      </div>
    </div>

    <van-overlay :show="show">
      <div class="overlays">
        <div class="overlay-box ma-b-10">
          <van-icon color="#fff" @click="show = false" name="cross" size="30" />
          <div class="title ma-b-20">绑定通道</div>
          <div class="flex f-center f-jcenter">
            <div class="fon-18">通道：</div>
            <el-select v-model="entranceId" placeholder="请选择">
              <el-option
                v-for="item in list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="width100 ma-t-30">
            <van-button
              type="primary"
              class="width100"
              color="#003AA9"
              @click="submit"
              >确认</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
let _this;
import { mapActions } from "vuex";
export default {
  data() {
    return {
      show: false,
      list: [],
      entranceId: "",
    };
  },
  created() {
    _this = this;
    _this.loadData();
    if (_this.$store.state.entranceId) {
      _this.entranceId = Number(_this.$store.state.entranceId);
    }
  },
  methods: {
    ...mapActions(["BINDENTRANCE", "LOGIN_OUT"]),

    // 加载数据
    loadData() {
      _this
        .$request({
          url: "/security/entrance",
          method: "GET",
        })
        .then((res) => {
          _this.list = res;
        });
    },

    // 绑定通道
    submit() {
      if (_this.$validator.isEmpty(_this.entranceId)) {
        return _this.$toast.fail("请选择通道");
      }
      _this.BINDENTRANCE(_this.entranceId).then(() => {
        _this.$toast.success({
          duration: "1500",
          message: "绑定成功",
          onClose() {
            _this.show = false;
          },
        });
      });
    },

    // 退出登录
    exit() {
      _this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认退出?",
        })
        .then(() => {
          _this.LOGIN_OUT().then(() => {
            _this.$toast.success({
              message: "退出成功",
              duration: "1000",
              onClose() {
                _this.$router.replace("/login");
              },
            });
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 300px;
  height: 164px;
  background: #f18100;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 70px;
  }
  &.box1 {
    background: #003aa9;
  }
}

.overlays {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .overlay-box {
    width: 320px;
    padding: 20px 30px;
    border-radius: 5px;
    background: #ffffff;
    text-align: left;
    position: relative;
    .title {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    .el-select {
      width: 75%;
    }
    .van-icon {
      position: absolute;
      top: 0;
      right: -40px;
    }
  }
}
.btn {
  width: 300px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
}
.green {
  background: #4e9900;
}
</style>